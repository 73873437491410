<template>
  <div class="news_details_wrap">
    <PCNavbar selectIndex="4-2" />
    <MobileNavBar :currentActive="3" />
    <div class="news_content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="news_bread">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
      <form name="_newscontent_fromname">
        <h1 class="h1tit">
          世界先进制造业大会 | 智能制造高峰论坛在山东省济南市召开
        </h1>
        <h4 class="h4tit">
          来源：苏州赛迪公司&nbsp;&nbsp;作者：&nbsp;&nbsp;投稿时间：2021-11-08
        </h4>
        <div id="vsb_content_1002" class="nr-div-p">
          <div class="v_news_content">
            <p class="vsbcontent_start">
              2021年11月3日上午，以“智造赋能
              变革跃升”为主题的世界先进制造业大会智能制造高峰论坛在山东省济南市山东大厦隆重举行。本次论坛致力于打造高水平合作交流平台，携手构建我国智能制造发展生态。<br />
            </p>
            <p>
              智能制造将物联网、大数据、云计算等新一代信息技术与先进自动化技术、传感技术、控制技术、数字制造技术结合，实现工厂和企业内部、企业之间和产品全生命周期的实时管理和优化。智能制造是制造业创新发展的主要抓手，是制造业转型升级的主要路径。山东省一直高度重视智能制造发展，牢牢把握新一轮科技革命和产业变革重大机遇，加快实施新旧动能转换重大工程，在实践中不断探索。为了推进山东省“制造强省”建设，加快山东省制造业智能化升级，本次论坛广邀有关省市政府、国内外知名制造业厂商、智能制造解决方案服务商、产业链上下游配套企业、专业科研院所、新闻媒体的领导及嘉宾齐聚一堂，为山东省智能制造生态建设发展献计献策。
            </p>
            <img src="../../assets/img/news/21.jpg" class="img_vsb_content" />
            <p>
              山东省工业和信息化厅副厅长王新生致欢迎辞，表示山东省将继续推进智能制造发展，打造创新型现代化产业体系，推动战略性新兴产业加快向主导产业转变。
            </p>
            <p>
              济南市工信局副局长杨福涛对济南市智能制造与高端装备产业高质量发展实施方案进行了介绍。济南市在经济发展的过程当中，以智能制造为主攻方向，聚焦重点领域产业链群建设，实施创新能力提升、重点领域产业链强链补链、智能制造推进、产业生态打造等工程，推进装备制造业高质量发展，打造高端装备产业成为济南市新旧动能转换主引擎、智造济南新标杆、工业强市主战场，构建了智能制造创新生态圈，为产业升级转型提供了黏合剂和助推剂。
            </p>
            <p>
              华中科技大学教授、博士生导师、国家数控系统工程技术研究中心主任、武汉华中数控股份有限公司董事长陈吉红做了《走向新一代智能机床》的主题演讲。陈教授表示，发展智能制造已上升为国家战略，作为工业母机，机床智能化发展将对工业智能化发展发挥关键作用。陈教授表示，新一代人工智能技术与数控机床的深度融合，将为机床产业带来新的变革。这是中国机床行业实现从“跟跑”到“领跑”的重大机遇，所取得的战略性突破对数控机床具有革命性的质变作用，正在形成推动经济社会发展的巨大引擎。
            </p>
            <img src="../../assets/img/news/22.jpg" class="img_vsb_content" />
            <p>
              菲尼克斯(中国)投资有限公司副总裁杨斌做《“灯塔”指引未来制造业数字化变革发展之路》的主题演讲。杨斌首先对“灯塔工厂”进行了解析，展示了菲尼克斯电气的数字化战略，为工业4.0时代中国的实践之路提供参考。“灯塔工厂”充分体现了数字化转型对提升生产力的价值，并创造出了可持续的盈利增长模式。其次，数字化流程与装备优化、预防性维护和精益规划等，都将帮助企业继续优化资源使用效率，进而提高生态效率。杨斌最后介绍了中德智能制造的合作示范项目与成果，表示菲尼克斯新一代智能工厂将助力中国制造绿色、精益、智能发展。
            </p>
            <img src="../../assets/img/news/23.jpg" class="img_vsb_content" />
            <p>
              齐鲁工业大学（山东省科学院）发展规划与学科建设处处长、研究员，山东省经济和信息化发展研究院院长李刚以“浅析山东智能制造领域标准化实施和应用”为主题发表了演讲。李刚处长分别从智能制造现状、智能制造标准、实际应用以及标准起草单位简介四个方面介绍了智能制造的标准，并指出，我国目前转向高质量发展阶段将为智能制造发展提供诸多便利。智能制造行业标准不统一将造成行业发展混乱，阻碍智能制造行业的发展壮大。李院长通过介绍智能制造标准的实际应用，展示了山东省在建设指南、水平与能力、案例应用方面的成果。
            </p>
            <img src="../../assets/img/news/24.jpg" class="img_vsb_content" />
            <p>
              赛迪智库产业政策研究所所长董凯发表演讲《”十四五“智能制造发展规划解读及趋势研判》，深刻解析了智能制造“十四五”重点任务，并提出“十四五”智能制造发展趋势，即工业数据将成为新时期智能制造发展的核心要素，工艺数字化将成为新时期智能制造发展的突破口、新型智能制造装备将成为新时期智能制造发展的重要驱动力、工业软件将成为新时期智能制造发展的核心引擎，融合发展的下一代工业网络将成为新时期智能制造发展的关键基础，“自下而上”生长的工业互联网平台将构建新时期工业操作系统，边云协同将成为新时期我国智能制造发展的主要路径，基于工业电商平台的“端到端”价值链贯通将引领新时期智能制造发展的新模式，全价值链精益管理是新时期智能制造发展的基石，生态体系建设将成为智能制造发展的关键。
            </p>
            <img src="../../assets/img/news/25.jpg" class="img_vsb_content" />
            <p>
              达索系统大中华区工业装备行业总监司现锋发表了《平台赋能·数智未来》主题演讲。介绍了达索系统帮助制造业企业数字化、智能化转型的先进经验。达索系统过去多年来与中国各行业头部企业通力合作，助力全球头部企业实现可持续发展目标。达索系统未来将从物理世界的数字化转型实现生命科学的数字化，实现基于体验的虚实数字孪生。
            </p>
            <img src="../../assets/img/news/26.jpg" class="img_vsb_content" />
            <p>
              百度智能云工业能源产品研发总经理黄锋介绍了百度开物平台打造AI+工业互联网的实践经验及案例。百度智能云通过设备智能、产线智能、企业智能实现从点到线到面，全方位助力企业智能化升级。
            </p>
            <img src="../../assets/img/news/27.jpg" class="img_vsb_content" />
            <p>
              青岛宝佳自动化设备有限公司系统集成中心总监、山东省联合智能制造研究院执行院长张光瑞在论坛上做了《场内物流机器人应用与柔性制造》主题演讲，分享了宝佳智能制造的探索。通过结合智能制造的柔性需求，讲述了引入物流机器人应用的重要性，并介绍了青岛宝佳的上下料机器人、分拣机器人以及自动装箱、自动码垛、自动仓储设备以及各个单元的连接设备。并表示，山东省联合智能制造研究院依托各方优势资源，致力于智能制造技术、系统、方案、标准、服务的研究与开发，聚焦智能制造关键共性技术研究、智能制造能力水平标准体系研究、高端智能装备研制、智能制造试点示范和相关产业服务发展，立足山东，面向全国，有效提升制造企业智能化水平，推进山东省新旧动能转换重大工程建设进度。
            </p>
            <img src="../../assets/img/news/28.jpg" class="img_vsb_content" />
            <p>
              河北省数据驱动工业智能工程研究中心主任，工学博士，精诺数据创始人、董事长刘晶分享了灯塔工厂数字化解决方案领域发展的经验，以“一眼看全、一眼看透、一眼看穿”三个角度剖析了“灯塔工厂”的本质，揭示了数字化、智能化转型中如何为工厂解决堵点问题、痛点问题和盲点问题。
            </p>
            <img src="../../assets/img/news/29.jpg" class="img_vsb_content" />
            <p>
              济南二机床集团有限公司副总经理赵晋荣分别从标准体系、智能冲压、智能服务、智能工厂、前沿趋势五个部分介绍了冲压设备产业。赵晋荣认为，随着5G等信息技术的快速发展，智能制造和工业互联网的技术和应用将发生新的变化，多技术交叉融合使得企业、行业之间的合作越来越紧密，特别是上下游之间的协同发展将更加重要。供应链和协同机制结合，将不断提升智能制造生产体系的创新水平。
            </p>
          </div>
        </div>
        <div id="div_vote_id"></div>
        <div class="clear"></div>
        <div class="clear"></div>
        <!-- <h3 style="text-align: center">
          【下一篇】:
          <a href="33332.htm">苏州市副市长沈觅一行调研苏州赛迪公司</a>
        </h3> -->
      </form>
    </div>
  </div>
</template>

<script>
import PCNavbar from '@/components/report_list/SpaNav.vue'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import moment from 'moment'
export default {
  created() {},
  data() {
    return {
      moment,
    }
  },
  components: {
    PCNavbar,
    MobileNavBar,
  },
  methods: {},
}
</script>

<style lang="scss">
.news_content{
  width: 50%;
  margin: 0 auto;
  padding-top: 100px;
  @media screen and (max-width:768px){
    width: 90%;
  }
  .news_bread{
    font-size: 14px;
    margin-bottom: 10px;
    @media screen and (max-width:768px){
      font-size: 14px;
    }
  }
}
.nr-div-p {
  text-align: left;
  font-weight: normal;
  line-height: 30px;
  margin: 0 25px;
  margin-top: 15px;
  margin-bottom: 20px;
  text-indent: 2em;
  color: #000;
}

.nr-div-p p {
  text-align: left;
  font-size: 14PX;
  line-height: 30PX;
  font-weight: normal;
  color: #000;
  @media screen and (max-width:768px){
    line-height: 24px;
    font-size: 14px;
    text-indent: 2em;
    padding: 10px 0;
  }
}
.clear {
  clear: both;
}
.img_vsb_content{
  width: 70%;
}
.h1tit{
  @media screen and (max-width:768px){
    font-size: 20px;
  }
}
.h4tit{
  @media screen and (max-width:768px){
    font-size: 14px;
  }
}
.v_news_content{
  text-align: center;
  img{
    @media screen and (max-width:768px){
      width: 90%;
    }
  }
}
</style>